import gsap from "gsap";

//______VARIABLES______//

const
body = document.querySelector("body"),
burger = document.getElementById("burger"),
menu = document.getElementById("menu"),
service_link = document.getElementById("service-link"),
main_navigation = document.getElementById("main-navigation"),
second_navigation = document.getElementById("second-navigation"),
second_navigation_link = document.querySelectorAll("#second-navigation span:not(.back-navigation)"),
third_navigation = document.querySelectorAll(".third-navigation"),
second_back = document.querySelectorAll(".back-navigation.second-back"),
third_back = document.querySelectorAll(".back-navigation.third-back"),
go_up = document.getElementById('go-up');

let 
isMobile = window.innerWidth<900,
menu_state = false,
lastScrollTop = window.scrollY;

window.addEventListener('scroll', function() {
  const currentScrollY = window.scrollY;
  const windowHeight = window.innerHeight;
  const bodyHeight = document.body.offsetHeight;

if(window.pageYOffset === 0){
    gsap.to(go_up,{x: "300%"})
}else if ( currentScrollY < lastScrollTop || (windowHeight + currentScrollY) >= bodyHeight ) {
    gsap.to(go_up,{x: 0});
}else {
    gsap.to(go_up,{x: "300%"});
}

  lastScrollTop = currentScrollY;
});

go_up.addEventListener('click', function() {
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
});


//______MENU______//

const open_menu = ()=>{
    body.style.overflow = "clip";
    gsap.timeline()
    .to(["#line_1", "#line_3"],{y:10.256},"0")
    .to(["#line_1", "#line_2", "#line_3"],{autoAlpha:0},"0")
    .to(["#line_4", "#line_5"],{autoAlpha:1, delay:0.3},"0")
    gsap.timeline()
    .to(menu,{y:0, autoAlpha:1, duration:1, ease:"expo.out"})
};
const close_menu = () =>{
    body.style.overflow = "inherit";
    gsap.timeline()
    .to(["#line_4", "#line_5"],{autoAlpha:0},"0")
    .to(["#line_1", "#line_2", "#line_3"],{autoAlpha:1},"0")
    .to(["#line_1"],{y:0},"0")
    .to(["#line_3"],{y:20.512},"0")
    .to(menu,{y:"-120%", autoAlpha:0},"0")
    .to(main_navigation,{x:0, autoAlpha:1},"0")
    .to(second_navigation,{x:200, autoAlpha:0},"0")
    .to(third_navigation,{x:200, autoAlpha:0},"0")
};
burger.addEventListener("click",()=>{
    if(menu_state){
        close_menu();
    }else{
        open_menu();
    }
    menu_state = !menu_state;
});

//

window.addEventListener("resize", () => {
    isMobile = window.innerWidth<900;
});

//Open all service navigation 2
service_link.addEventListener("click", (e) => {
    e.preventDefault();
    gsap.timeline()
    .to(main_navigation,{x:-200, autoAlpha:0},"0")
    .to(second_navigation,{x:0, autoAlpha:1, delay:0.2},"0");
});

//Back to navigation 1 from 2
second_back.forEach(e => {
    e.addEventListener("click", () => {
        gsap.timeline()
        .to(second_navigation,{x:200, autoAlpha:0},"0")
        .to(main_navigation,{x:0, autoAlpha: 1, delay:0.2},"0")
    });
});

//Back to navigation 2 from 3
third_back.forEach(e => {
    e.addEventListener("click", () => {
        gsap.timeline()
        .to(third_navigation,{x:200, autoAlpha:0},"0")
        .to(second_navigation,{x:0, autoAlpha:1, delay:0.2},"0")        
    });
});

//Go to navigation 3 from 2
second_navigation_link.forEach(e => {
    e.addEventListener("click", (e) => {
        e.preventDefault();
        const id = e.target.id.replace("link_","");
        const current_nav = document.querySelector(`#navigation_${id}`);

        gsap.timeline()
        .to(second_navigation,{x:-200, autoAlpha: 0},"")
        .to(current_nav,{x:0, autoAlpha: 1, delay:0.2},"0");
    })

})

export {isMobile};